<template>
<!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <div>
    <Content-Header title="Home ku"/>
    <Content>
        <div class="home"> peace
        <img alt="Vue logo" src="../../assets/logo.png">
        
        </div>
        {{ url }}
        url nya
        <p>{{rute}}</p>
    </Content>
 
    </div>
    
    
</template>

<script>
export default {
    setup() {
        
    },
    data () {
      return {
        url: this.$route.path,
        rute: this.$router.options.routes,
          loggedIn: sessionStorage.getItem('loggedIn'),
            //state token
            //token: localStorage.getItem('token'),
            token: sessionStorage.getItem('token'),
            //state user logged In
            user: []
      };
    },
            //check user already logged in
    mounted() {
      if(!this.loggedIn) {
            return this.$router.push({ name: 'login' })
        }
    }
  
    
}
</script>