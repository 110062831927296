<template>
    <div class="home">
        <div class="container" style="margin-top:100px">
            <div class="jumbotron">
                <div class="container">
                <h1 class="display-3">SANTRIKODING</h1> tes
                <p>Belajar Membuat Authentication Dengan Laravel Sanctum dan Vue Js. 1</p>
                <p><a class="btn btn-primary btn-lg" href="#" role="button">Selengkapnya &raquo;</a></p>
                </div>
            </div>        
        </div>    
    </div>
</template>

<script>
export default {
    name: 'Home'
}
</script>