<template>
<!-- <div class="content-wrapper"> -->
    <!-- <div class="dashboard" style="margin-top:80px"> -->
         <section class="content">
        <div class="container-fluid">
       <!-- <div class="items">  -->
        <!-- <div class="container"> -->
            <div class="row">
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body">
                            MAIN MENU
                            <hr>
                            <ul class="list-group">
                                <router-link :to="{name: 'dashboard'}" class="list-group-item text-dark text-decoration-none">DASHBOARD</router-link>
                                <li @click="logout" class="list-group-item text-dark text-decoration-none" style="cursor:pointer">LOGOUT</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-body">
                            DASHBOARD
                            <hr>
                            Selamat Datang <strong>{{ user.name }}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       <!-- </div> -->
         </section>
    <!-- </div> -->
<!-- </div> -->
</template>

<script>

import axios from 'axios'

export default {
    name: 'Dashboard',

    data() {
        return {
            //state loggedIn with localStorage
            //loggedIn: localStorage.getItem('loggedIn'),
            loggedIn: sessionStorage.getItem('loggedIn'),
            //state token
            //token: localStorage.getItem('token'),
            token: sessionStorage.getItem('token'),
            //state user logged In
            user: []
        }
    },

    created() {
        /* axios.post(this.urlApi+'login', {
        axios.get('http://localhost:8000/api/user', {headers: {'Authorization': 'Bearer '+this.token}}) */
        axios.get(this.urlApi+'user', {headers: {'Authorization': 'Bearer '+this.token}})
        .then(response => {
            console.log(response)
            this.user = response.data // assign response to state user
        })
    },

    methods: {
        logout() {
            //axios.get('http://localhost:8000/api/logout')
            axios.get(this.urlApi+'logout')
            .then(() => {
                //remove localStorage
                //localStorage.removeItem("loggedIn")    
                sessionStorage.clear();


                //redirect
                return this.$router.push({ name: 'login' })
            })
        }
    },

    //check user logged in or not
    mounted() {
        if(!this.loggedIn) {
            return this.$router.push({ name: 'login' })
        }
    }
}
</script>

<style>
    body {
        background-color: #F0F0F0;
    }
    h1 {
        padding: 0;
        margin-top: 0;
    }
    #app {
        width: 1024px;
        margin: auto;
    }

   
</style>